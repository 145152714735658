<template>
  <v-text-field
    :label="label"
    :value="value"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="320" persistent>
        <v-card>
          <v-card-title>实时视频地址配置</v-card-title>
          <v-card-text>
            <v-text-field label="设备地址" v-model="host"></v-text-field>
            <v-text-field label="设备端口" v-model="port"></v-text-field>
            <v-text-field label="用户名" v-model="account"></v-text-field>
            <v-text-field label="密码" v-model="password"></v-text-field>
            <v-select
              label="视频厂商"
              v-model="manufacturer"
              :items="manufacturers"
            ></v-select>
            <v-text-field label="通道" v-model="channel"></v-text-field>
            <v-select
              label="码流"
              v-model="streamType"
              :items="streamTypes"
            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import client from "../../utils/client";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,
      host: "",
      port: 554,
      account: "",
      password: "",
      manufacturer: 1,
      manufacturers: [
        { text: "海康", value: 1 },
        { text: "大华", value: 2 },
      ],
      channel: "1",
      streamType: "2",
      streamTypes: [
        { text: "主码流", value: "1" },
        { text: "子码流", value: "2" },
      ],
    };
  },
  methods: {
    submit() {
      if (
        !this.host ||
        !this.port ||
        !this.account ||
        !this.password ||
        this.channel == ""
      ) {
        client.$emit("toast", "请输入完整信息");
        return;
      }
      this.show = false;
      let url = "";
      if (this.manufacturer == 1) {
        url = `rtsp://${this.account}:${this.password}@${this.host}:${this.port}/Streaming/Channels/${this.channel}0${this.streamType}`;
      } else if (this.manufacturer == 2) {
        url = `rtsp://${this.account}:${this.password}@${this.host}:${
          this.port
        }/cam/realmonitor?channel=${this.channel}&subtype=${
          this.streamType - 1
        }`;
      }
      this.change(url);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
